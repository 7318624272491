/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: 'poppinsB', "Helvetica Neue", sans-serif; }
@font-face {
    font-family: 'poppinsB';
    src: url('./assets/Poppins-Black.ttf') format("truetype");
}
@font-face {
    font-family: 'poppinsR';
    src: url('./assets/Poppins-Regular.ttf') format("truetype");
}

@import url('https://db.onlinewebfonts.com/c/1cd90503ccb8043031bf87c4e45d53d7?family=MHeiHK');

@font-face {
    font-family: 'MHeiHK';
    src: url('https://db.onlinewebfonts.com/c/1cd90503ccb8043031bf87c4e45d53d7?family=MHeiHK');
}

.MHeiHK{
    font-family: "MHeiHK";
}

.poppinsR {
    font-family: "poppinsR", Times, serif;
}
.poppinsB {
    font-family: "poppinsB", Times, serif;
    font-weight: 200;
}
.poppinsM {
    font-family: 'Poppins', sans-serif;
    font-weight: 550;
}

.label_title{
    margin-left: 70px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
}

.content_title{
    margin-left: 10px;
}

.block{
    position: relative;
    padding: 0 25px;
    padding-top: 50px;
}

.block-label .icon{
    color: white;
    width: 36px;
    height: 36px;
    font-size: 36px;
}

.componenttitle{
    margin-left: 100px;
    font-family: poppinsR;
    font-weight:700;
    font-size:20px;
}

.title{
    margin-top: 5px;
    margin-left: 115px;
    font-family: poppinsR;
    font-weight:700;
    font-size:20px;
}

.formerror{
    height:1.5em;
}

.eng_content1{
    font-size: 15px;
    font-family: poppinsR;
}

.div_paddingbottom{
    padding-bottom: 15px;
}

.greentextcontent{
    color:#1b9d77;
    font-size: 18px;
    font-weight: 400;
}

@media (min-width: 320px) {
    .heading-margin{
        margin-top:40px;
    }
    
.component-margin{
    margin-top:30px
}

.payment_margintop{
    margin-top: 30px;
}


.button_margintop{
    margin-top: 20px;
}


.transpottime{
    padding-bottom: 10px;
    padding-top:10px;
}

.eng_content1{
    font-size: 15px;
    font-family: poppinsR;
    }

.eng_content2{
    font-size: 18px;
    font-family: poppinsB;
    }    

.Steppercontent-margintop{
    margin-top:15px !important;
}

.steppertitle-margintop{
    margin-top: 25px;
}

.stepperdes{
    margin:20px 0;
    font-size: 12px;
}



.content-margintop{
    margin-top:15px;
}


.block-top{
    position: absolute;
    top:0;
    left:0;
    margin-top: 10px !important;
}

.block-top .title{
    margin-top: 5px;
    margin-left: 115px;
}

.block-label{
    background: #1B9D77;
    position: absolute;
    top: -40px;
    left: 20px;
    display: block;
    border-radius: 3px;
    padding: 10px;
    line-height: 0;
    box-shadow: 0 7px 6px rgba(0,0,0,0.16), 0 7px 6px rgba(0,0,0,0.23)
}

.block-label .icon{
    color: white;
    width: 50px;
    height: 50px;
    font-size: 50px;
}

.block-top .title{
    margin-top: 5px;
    margin-left: 115px;
}
}

@media (min-width: 768px) {
    .whiteboard-mid{
        width: 600px;
        margin:auto;
        padding: 0 0 15px 0;  
    }
}

@media (min-width: 960px) {
    .whiteboard-mid{
        width: 600px;
        margin: auto;
        padding: 0px 0 15px 0; 
    }
} 

@media (min-width: 1024px) {
    .whiteboard-mid{
        width: 600px;
        margin: auto;
        padding: 0px 0 15px 0; 
    }
}

@media (min-width: 1280px) {
    .whiteboard-mid{
        width: 600px;
        margin: auto;
        padding: 0px 0 15px 0; 
    }
}

@media(min-width: 1920px){
    .whiteboard-mid {
        width: 600px;
        margin: auto;
        padding: 0px 0 15px 0;    
    }
} 

* {
    line-break: auto !important;
}